'use client'
import { useState } from 'react'
import { Button } from 'src/components/Button'
import { addToWaitlist } from '../utils/api'

const JoinWaitlist = ({ lightText }: { lightText?: boolean }) => {
    const [emailInput, setEmailInput] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [joined, setJoined] = useState(false)
    const submit = () => {
        setSubmitting(true)
        addToWaitlist(emailInput)
            .then(() => {
                setEmailInput('')
                setSubmitting(false)
                setJoined(true)
            })
            .catch(err => {
                setSubmitting(false)
            })
    }
    return (
        <div className="flex flex-col sm:flex-row items-stretch sm:items-center w-full mt-10 gap-3">
            {!joined && (
                <>
                    <input
                        disabled={submitting}
                        type={'email'}
                        value={emailInput}
                        placeholder="Enter your email"
                        className={
                            'block appearance-none rounded border border-neutral-200 p-3 text-neutral-900 placeholder-neutral-400 focus:border-primary-500 focus:bg-white focus:outline-none focus:ring-primary-500 text-m flex-1'
                        }
                        onChange={e => setEmailInput(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                submit()
                            }
                        }}
                    />
                    <Button
                        disabled={submitting || emailInput.trim() === ''}
                        color="slate"
                        className="relative disabled:opacity-50 disabled:cursor-default disabled:pointer-events-none disabled:select-none"
                        onClick={() => {
                            submit()
                        }}
                    >
                        <span className="font-normal text-xl">Join the waitlist</span>
                    </Button>
                </>
            )}
            {joined && (
                <>
                    <p className={`ml-4 max-w-xl text-xl font-bold ${lightText ? 'text-white' : ''}`}>
                        Thanks for joining our waitlist, we&apos;ll keep you posted!
                    </p>
                </>
            )}
        </div>
    )
}

export default JoinWaitlist
